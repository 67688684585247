import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["blockToDisplay"]

  connect() {
    this.display()
  }

  display() {
    this.toggleElements()
    this.toggleCheckboxElements()
  }

  // Itère sur tous les éléments à afficher/masquer en fonction de data-display-if
  toggleElements() {
    this.blockToDisplayTargets.forEach((elt) => {
      if (!elt.dataset.displayIf) return

      let displayConditions = JSON.parse(elt.dataset.displayIf)
      let conditions = this.conditionIterator(displayConditions)
      let visibilityStatus = this.visibilityStatusChecker(elt, conditions)
      elt.classList.toggle("d-none", !visibilityStatus)
    });
  }

  // Gère l'affichage des éléments basés sur l'état des cases à cocher (checkbox)
  toggleCheckboxElements() {
    this.blockToDisplayTargets.forEach((elt) => {
      if (!elt.dataset.displayIfChecked) return
      const input = this.element.querySelector("#" + elt.dataset.displayIfChecked)
      let visibilityStatus = input?.checked
      elt.classList.toggle("d-none", !visibilityStatus)
    });
  }

  // private

  // Méthode privée pour vérifier chaque condition dans data-display-if
  conditionIterator(displayConditions) {
    let conditionArray = []

    for (const [id, condition] of Object.entries(displayConditions)) {
      const input = this.element.querySelector("#" + id) // Trouve l'input par ID

      if (!input) {
        conditionArray.push(false) // Si l'input n'est pas trouvé, retourne false
        continue
      }

      if (condition === "checkbox_checked") {
        conditionArray.push(this.handleCheckboxChecked(input)) // Gestion des cases à cocher
        continue
      }

      switch (typeof condition) {
        case "boolean":
          conditionArray.push(this.handleBooleanCondition(condition, input))
          break
        case "number":
        case "string":
          conditionArray.push(this.handleStringOrNumberCondition(condition, input))
          break
        case "object":
          conditionArray.push(this.handleObjectCondition(condition, input))
          break
        default:
          conditionArray.push(false)
      }
    }

    return conditionArray
  }

  // Gère les conditions boolean (true/false)
  handleBooleanCondition(condition, input) {
    return condition === true && input?.value
  }

  // Vérifie si une case à cocher est cochée
  handleCheckboxChecked(input) {
    return input?.checked
  }

  // Gère les conditions basées sur une chaîne de caractères ou un nombre (ex: "checked", valeur numérique)
  handleStringOrNumberCondition(condition, input) {
    if (condition === "checked") {
      return input?.checked || false // Si la condition est "checked", vérifie l'état de l'input
    }
    return condition.toString() === input?.value // Sinon, compare la valeur
  }

  // Gère les tableaux ou les objets complexes dans data-display-if
  handleObjectCondition(condition, input) {
    const valueToCheck = input?.checked ? "checked" : input?.value;

    // Si c'est un tableau, vérifie si valueToCheck est inclus dans le tableau
    if (Array.isArray(condition)) {
      return condition.includes(valueToCheck);
    }

    // Sinon, compare directement valueToCheck à la condition
    return condition === valueToCheck;
  }

  // Détermine la logique de vérification des conditions (AND par défaut, OR si spécifié)
  visibilityStatusChecker(elt, conditions) {
    // "AND" par défaut : toutes les conditions doivent être vraies
    if (elt.dataset.conditionOperator && elt.dataset.conditionOperator === "OR") {
      return conditions.some(Boolean) // Utilise "OR" si spécifié dans data-condition-operator="OR"
    } else {
      return conditions.every(Boolean) // "AND" par défaut
    }
  }
}

/*
   --- Instructions d'utilisation ---

   1. **Définir les cibles d'affichage conditionnel** :
      - Utilisez `data-display-if` pour définir les conditions d'affichage des éléments.
      - Exemple : `data-display-if='{"input_id": "checked", "other_input_id": "value"}'`
      - La clé correspond à l'ID de l'input, et la valeur peut être :
        - `"checked"` : Pour vérifier si un bouton radio ou une case à cocher est sélectionné.
        - `"value"` : La valeur attendue de l'input pour afficher l'élément.

   2. **Utilisation des opérateurs logiques** :
      - Par défaut, toutes les conditions dans `data-display-if` doivent être remplies pour afficher l'élément (logique "AND").
      - Pour changer cette logique en "OR" (afficher l'élément si l'une des conditions est remplie), ajoutez l'attribut :
        `data-condition-operator="OR"`
      - Exemple :
        ```html
        <div data-display-if='{"input1": "checked", "input2": "value"}'
             data-condition-operator="OR">
          Affiche cet élément si input1 est checked ou input2 a la valeur correcte.
        </div>
        ```

   3. **Conditions spécifiques pour les cases à cocher** :
      - Utilisez `data-display-if-checked` pour gérer les cases à cocher :
        - Exemple :
          ```html
          <div data-display-if-checked="checkbox_id">
            Affiche cet élément si la case est cochée.
          </div>
          ```

   4. **Exemple de vue HTML** :
      ```html
      <div data-controller="conditional-display">
        <%= f.input :option, as: :radio_buttons, collection: ["Option 1", "Option 2"], input_html: { id: "option_radio" } %>

        <div class="d-none" data-conditional-display-target="blockToDisplay" data-display-if='{"option_radio": "checked"}'>
          Affiche cet élément lorsque l'option est sélectionnée.
        </div>
      </div>
      ```

   5. **Appel automatique des vérifications** :
      - Lors du chargement de la page, le contrôleur `conditional-display` vérifie automatiquement les conditions et affiche ou masque les éléments appropriés.
      - Lors d'un changement d'état (ex : un bouton radio est sélectionné), les conditions seront vérifiées à nouveau et l'interface utilisateur sera mise à jour.
*/
